import type { AlgoliaProduct } from '@jane/search/types';
import type {
  CalloutVariants,
  MobileFilterTab,
  ShareNetwork,
} from '@jane/shared-ecomm/types';
import { config } from '@jane/shared/config';
import type {
  AppMode,
  BrandSpecial,
  Id,
  MenuProduct,
  PriceId,
  Product,
} from '@jane/shared/models';
import type { ReservationMode, UserSegmentIds } from '@jane/shared/types';
import {
  getActualUnitPrice,
  shouldProductShowSpecial,
} from '@jane/shared/util';

import { EventNames } from './eventNames';
import type {
  BrandDetailPageTap,
  CardType,
  CashBackDetailPageTap,
  ChangeBankAccountSuccess,
  ClickedBrandLink,
  DispensaryTap,
  HomeTap,
  LinkBankAccountEvent,
  LoadedLoyaltyPoints,
  LoggedIn,
  MenuTabTap,
  NavBarTap,
  ProductListingTap,
  ProductReview,
  ProductReviewInteractionTypes,
  SSOChannel,
  StoreInfoAttribute,
  UserPreferencesType,
} from './eventNames';
import { track } from './track';

export const trackCartClick = () => track({ event: EventNames.ClickedCart });

export const trackTimeSpentOnMenu = ({
  storeId,
  seconds,
}: {
  seconds: number;
  storeId: Id;
}) => track({ event: EventNames.TimedMenuLoad, storeId, seconds });

export const trackStoresPageLoad = ({ map }: { map: boolean }) =>
  track({ event: EventNames.LoadedStoresPage, map });

export const trackHomePageLoad = () =>
  track({ event: EventNames.LoadedHomePage });

export const trackClickedSegmentedMobileFilterTab = (
  filterTab: MobileFilterTab
) => track({ event: EventNames.ClickedSegmentedMobileFilterTab, filterTab });

export const trackClickedSegmentedMobileFilterToggle = (
  filterTab: MobileFilterTab
) => track({ event: EventNames.ClickedSegmentedMobileFilterToggle, filterTab });

export const trackClickedBrandLink = ({
  appMode,
  brandName,
  page,
  storeId,
}: Omit<ClickedBrandLink, 'event'>) =>
  track({
    event: EventNames.ClickedBrandLink,
    appMode,
    brandName,
    page,
    storeId,
  });

export const trackClickedStoresNearYou = () =>
  track({ event: EventNames.ClickedStoreNearYou });

export const trackClickedHomePageProductCard = (cardType: CardType) =>
  track({ event: EventNames.ClickedHomePageProductCard, cardType });

export const trackOpenedAccordionItem = (itemName: string) =>
  track({ event: EventNames.OpenedAccordionItem, itemName });

export const trackCheckoutClick = (reservationMode: ReservationMode) =>
  track({ event: EventNames.ClickedCheckout, reservationMode });

interface LoadedCartPageProperties {
  isEmpty: boolean;
  reservationMode: ReservationMode;
}
export const trackLoadedCartPage = (props: LoadedCartPageProperties) =>
  track({ event: EventNames.LoadedCartPage, ...props });

export const trackLoadedCheckoutPage = (reservationMode: ReservationMode) =>
  track({ event: EventNames.LoadedCheckoutPage, reservationMode });

export const trackLoadedGuestCheckoutPage = () =>
  track({ event: EventNames.LoadedGuestCheckoutPage });

export const trackTimedKioskInactivity = () =>
  track({ event: EventNames.TimedKioskInactivity });

export const trackTimedKioskRefreshToLanding = () =>
  track({ event: EventNames.TimedKioskRefreshToLanding });

export const trackStartedKioskOrder = () =>
  track({ event: EventNames.StartedKioskOrder });

export const trackChangeBankAccountTap = () => {
  track({ event: EventNames.ChangeBankAccountTap });
};

interface InteractedWithProductReviewProperties {
  aggregateRating?: number | null;
  interactionType: ProductReviewInteractionTypes;
  interactionValue: any;
  product: Product;
  reviewCount?: number | null;
  userSegments?: UserSegmentIds;
}
export const trackInteractedWithProductReview = ({
  product,
  interactionType,
  interactionValue,
  aggregateRating,
  reviewCount,
  userSegments,
}: InteractedWithProductReviewProperties) =>
  track({
    event: EventNames.InteractedWithProductReviews,
    interactionType,
    interactionValue,
    ...coreProductProperties(product, userSegments),
    aggregateRating,
    reviewCount,
  });

export const trackLoadedCrmPoints = ({
  storeHasProvider,
  memberPoints,
  crmProvider,
  crmRewards,
}: Omit<LoadedLoyaltyPoints, 'event'>) =>
  track({
    event: EventNames.LoadedLoyaltyPoints,
    storeHasProvider,
    memberPoints,
    crmProvider,
    crmRewards,
  });

export const trackReviewedProduct = (
  product: Product,
  review: ProductReview,
  activities: string[],
  feelings: string[],
  userSegments?: UserSegmentIds
) =>
  track({
    event: EventNames.ReviewedProduct,
    activities,
    review,
    feelings,
    ...coreProductProperties(product, userSegments),
  });

export const trackLoadedProductReviewForm = () =>
  track({ event: EventNames.LoadedWriteProductReviewForm });

export const trackContinueShoppingClick = () =>
  track({ event: EventNames.ClickedContinueShopping });

export const trackStoreInfoClick = (attribute: StoreInfoAttribute) =>
  track({ event: EventNames.ClickedStoreInfo, attribute });

export const trackExperimentView = (
  experimentName: string,
  variantName: string
) => track({ event: EventNames.ViewedExperiment, experimentName, variantName });

export const trackSharedProduct = ({
  productId,
  storeId,
  via,
}: {
  productId: string;
  storeId?: string;
  via: ShareNetwork;
}) => track({ event: EventNames.SharedProduct, productId, storeId, via });

export const coreProductProperties = (
  product: Product | MenuProduct | AlgoliaProduct,
  userSegments?: UserSegmentIds
) => {
  const availableWeights = (product as MenuProduct).available_weights;
  const showProductSpecial = shouldProductShowSpecial({
    product,
    userSegments,
  });

  let brandDiscountId;
  if (showProductSpecial) {
    brandDiscountId =
      product.applicable_brand_special_ids &&
      product.applicable_brand_special_ids[0];
    if (product.brand_special_prices) {
      const brandDiscountPriceWithId = Object.values(
        product.brand_special_prices
      ).filter((price) => !!price?.brand_special_id);
      brandDiscountId =
        brandDiscountPriceWithId.length > 0
          ? brandDiscountPriceWithId[0] &&
            brandDiscountPriceWithId[0].brand_special_id
          : brandDiscountId;
    }
  }

  const specialIds = (product as MenuProduct)
    .applicable_special_ids as number[];
  let hasSpecial = specialIds ? specialIds.length > 0 : undefined;
  hasSpecial = (product as MenuProduct).special_id
    ? !!(product as MenuProduct).special_id
    : hasSpecial;

  const hasMultipleWeights = availableWeights && availableWeights.length > 1;

  return {
    brandDiscountId,
    brandId: product.product_brand_id,
    brandName: product.brand,
    hasBrandDiscount:
      (showProductSpecial && product.has_brand_discount) || !!brandDiscountId,
    hasMultipleWeights,
    hasSpecial,
    productId:
      (product as Product).id?.toString() ||
      (product as AlgoliaProduct).product_id?.toString(),
    productName: product.name,
    productBrand: product.brand,
    productKind: product.kind,
    productRootSubtype: product.root_subtype,
    productBrandSubtype: product.brand_subtype,
    productLineage: (product as Product).category,
    productPercentCBD:
      product.percent_cbd || (product as Product).primaryPercentCBD,
    productPercentTHC:
      product.percent_thc || (product as Product).primaryPercentTHC,
    productAggregateRating: product.aggregate_rating,
    productReviewCount: product.review_count,
    specialIds,
  };
};

export const trackCustomerLogin = ({
  ssoChannel,
  source,
}: Omit<LoggedIn, 'event'>) => {
  track({ event: EventNames.LoggedIn, ssoChannel, source });
};

interface RegistrationProps {
  customer: { id: number; ssoChannel?: SSOChannel };
  partnerChannel: AppMode;
  partnerId?: Id;
  partnerName?: string;
}

export const trackCustomerRegistration = ({
  customer,
  partnerChannel,
  partnerId,
  partnerName,
}: RegistrationProps) => {
  janeMixpanel.alias(customer.id.toString());
  track({
    event: EventNames.Registered,
    ssoChannel: customer.ssoChannel,
    partnerChannel,
    partnerId,
    partnerName,
  });
};

interface StoreCommunicationBannerProps {
  bannerEnabled: boolean;
  bannerMessage: string | null | undefined;
  isExpanded: boolean;
  storeId: Id;
}

export const trackStoreCommunicationBannerLoad = ({
  storeId,
  bannerEnabled,
  bannerMessage,
  isExpanded,
}: StoreCommunicationBannerProps) => {
  const htmlRegex = /<[^>]*>/g;
  const standardizedMessage = bannerMessage?.replace(htmlRegex, '') || '';

  track({
    event: EventNames.StoreCommunicationBannerLoaded,
    storeId,
    bannerEnabled,
    bannerMessageCharacterCount: bannerEnabled ? standardizedMessage.length : 0,
    isExpanded,
  });
};

export type BrandDetailPageTapType = Omit<BrandDetailPageTap, 'event'>;

export const trackBrandDetailPageTap = ({
  brandDiscount,
  brandId,
  brandName,
  brandRankInList,
  cityState,
  source,
  storeFulfillmentType,
  storeSearchRadius,
  storeType,
  zipcode,
}: BrandDetailPageTapType) => {
  track({
    brandDiscount,
    brandId,
    brandName,
    brandRankInList,
    cityState,
    event: EventNames.BrandDetailPageTap,
    source,
    storeFulfillmentType,
    storeSearchRadius,
    storeType,
    zipcode,
  });
};

interface BrandPageProps {
  brandId: Id;
  brandName: string;
  productReferrerId?: Id;
}

export const trackBrandPageLoad = ({
  brandId,
  brandName,
  productReferrerId,
}: BrandPageProps) => {
  track({
    event: EventNames.BrandPageLoaded,
    brandId,
    brandName,
    productReferrerId,
  });
};

export const trackBrandPageOpenVideo = (brandId: Id) => {
  track({
    event: EventNames.BrandPageOpenedVideo,
    brandId,
  });
};

interface ResetKioskSessionProps {
  appStoreId: number | string;
  janeDeviceId: string | undefined;
}

export const resetKioskSession = ({
  janeDeviceId,
  appStoreId,
}: ResetKioskSessionProps) => {
  janeMixpanel.reset();

  janeMixpanel.register({
    app: 'kiosk',
    janeDeviceId,
    appStoreId: appStoreId || null,
    build: config.buildVersion.substring(0, 7),
  });
};

export type HomeTapType = Omit<HomeTap, 'event'>;

export const trackHomeTap = ({
  cityState,
  section,
  storeFulfillmentType,
  storeSearchRadius,
  storeType,
  tile,
  zipcode,
}: HomeTapType) => {
  track({
    cityState,
    event: EventNames.HomeTap,
    section,
    storeFulfillmentType,
    storeSearchRadius,
    storeType,
    tile,
    zipcode,
  });
};

export type NavBarTapType = Omit<NavBarTap, 'event'>;

export const trackNavBarTap = ({
  cityState,
  navBarTap,
  source,
  storeFulfillmentType,
  storeSearchRadius,
  storeType,
  zipcode,
}: NavBarTapType) => {
  track({
    cityState,
    navBarTap,
    event: EventNames.NavBarTap,
    source,
    storeFulfillmentType,
    storeSearchRadius,
    storeType,
    zipcode,
  });
};

export interface CashBackDetailPageTapType
  extends Omit<
    CashBackDetailPageTap,
    | 'event'
    | 'brandDiscountDetails'
    | 'brandDiscountId'
    | 'brandDiscountType'
    | 'brandId'
    | 'discountBrandName'
  > {
  brandSpecial: Partial<BrandSpecial> &
    Pick<
      BrandSpecial,
      | 'id'
      | 'discount_type'
      | 'product_brand_id'
      | 'discount_dollar_amount'
      | 'discount_percent'
      | 'discount_target_price'
    >;
}

export const trackCashBackDetailPageTap = ({
  brandSpecial,
  brandDiscountRankInList,
  brandName,
  cityState,
  source,
  storeFulfillmentType,
  storeSearchRadius,
  storeType,
  zipcode,
}: CashBackDetailPageTapType) => {
  let brandDiscountDetails = brandSpecial.discount_dollar_amount;
  brandDiscountDetails =
    brandSpecial.discount_type === 'percent'
      ? brandSpecial.discount_percent
      : brandDiscountDetails;
  brandDiscountDetails =
    brandSpecial.discount_type === 'target_price'
      ? brandSpecial.discount_target_price
      : brandDiscountDetails;
  track({
    brandDiscountDetails,
    brandDiscountId: brandSpecial.id,
    brandDiscountType: brandSpecial.discount_type,
    brandId: brandSpecial.product_brand_id,
    brandDiscountRankInList: brandDiscountRankInList + 1,
    brandName,
    cityState,
    discountBrandName: brandName,
    event: EventNames.CashBackDetailPageTap,
    source,
    storeFulfillmentType,
    storeSearchRadius,
    storeType,
    zipcode,
  });
};

export interface ProductListingTapType
  extends UserPreferencesType,
    Pick<
      ProductListingTap,
      | 'almostGone'
      | 'columnPosition'
      | 'rowPosition'
      | 'source'
      | 'storeId'
      | 'storeCurrency'
      | 'storeName'
      | 'storeCity'
      | 'storeState'
      | 'productLocation'
    > {
  brandSpecial?: Partial<BrandSpecial> &
    Pick<
      BrandSpecial,
      | 'id'
      | 'discount_type'
      | 'product_brand_id'
      | 'discount_dollar_amount'
      | 'discount_percent'
      | 'discount_target_price'
    >;
  product: MenuProduct | Product | AlgoliaProduct;
  userSegments?: UserSegmentIds;
}

export const trackProductListingTap = ({
  almostGone,
  brandSpecial,
  cityState,
  columnPosition,
  product,
  productLocation,
  rowPosition,
  source,
  storeId,
  storeName,
  storeCity,
  storeState,
  storeFulfillmentType,
  storeSearchRadius,
  storeType,
  userSegments,
  zipcode,
}: ProductListingTapType) => {
  let brandDiscountId =
    product.applicable_brand_special_ids &&
    product.applicable_brand_special_ids[0];
  let brandDiscountDetails = undefined;
  let brandDiscountType = undefined;

  const showProductSpecial = shouldProductShowSpecial({
    product,
    userSegments,
  });

  if (showProductSpecial && brandSpecial) {
    brandDiscountId = brandSpecial.id;
    brandDiscountType = brandSpecial.discount_type;
    brandDiscountDetails = brandSpecial.discount_dollar_amount;
    brandDiscountDetails =
      brandSpecial.discount_type === 'percent'
        ? brandSpecial.discount_percent
        : brandDiscountDetails;
    brandDiscountDetails =
      brandSpecial.discount_type === 'target_price'
        ? brandSpecial.discount_target_price
        : brandDiscountDetails;
  }

  const availableWeights = (product as AlgoliaProduct).available_weights;
  const priceUnit: PriceId = (
    availableWeights && availableWeights.length > 0
      ? availableWeights[0]
      : 'each'
  ) as PriceId;

  const pricePerUnit = getActualUnitPrice(product as MenuProduct, priceUnit);

  const coreProperties = coreProductProperties(product, userSegments);

  track({
    event: EventNames.ProductListingTap,
    ...coreProperties,
    almostGone,
    brandDiscountId: brandDiscountId || coreProperties.brandDiscountId,
    brandDiscountDetails,
    brandDiscountType,
    cityState,
    columnPosition,
    productLocation,
    pricePerUnit,
    rowPosition,
    source,
    storeId,
    storeName,
    storeCity,
    storeState,
    storeFulfillmentType,
    storeSearchRadius,
    storeType,
    zipcode,
  });
};

export const linkBankAccountEvent = ({
  email,
  event,
  isJanePay,
  source,
  username,
}: Omit<LinkBankAccountEvent, 'source'> & { source?: CalloutVariants }) => {
  const getSource = (): LinkBankAccountEvent['source'] => {
    if (
      !source ||
      [
        EventNames.LinkBankAccountFailed,
        EventNames.UnlinkedLastBankAccount,
      ].includes(event)
    ) {
      return source;
    }

    return `${source}${isJanePay ? ' (Pay)' : ' (Gold)'}`;
  };

  track({
    email,
    event,
    source: getSource(),
    username,
  });
};

export const trackMenuTabTap = ({
  source,
  appMode,
  storeId,
  storeName,
  storeCity,
  storeState,
  storeCurrency,
  productLocation,
}: Omit<MenuTabTap, 'event'>) => {
  track({
    event: EventNames.MenuTabTap,
    source,
    appMode,
    storeId,
    storeName,
    storeCity,
    storeState,
    storeCurrency,
    productLocation,
  });
};

export const trackChangeBankAccountSuccess = ({
  source,
}: Omit<ChangeBankAccountSuccess, 'event'>) => {
  track({
    event: EventNames.ChangeBankAccountSuccess,
    source,
  });
};

export const trackDispensaryTap = (props: Omit<DispensaryTap, 'event'>) => {
  track({
    event: EventNames.DispensaryTap,
    ...props,
  });
};
