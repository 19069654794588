import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { JaneGoldCallout } from '@jane/shared-ecomm/components';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import { trackCheckoutClick } from '@jane/shared-ecomm/tracking';
import { getInitialDeliveryAddress } from '@jane/shared-ecomm/util';
import {
  Accordion,
  productDetailLink as getProductDetailLink,
} from '@jane/shared/components';
import { useGetStore } from '@jane/shared/data-access';
import { useShouldShowGold } from '@jane/shared/hooks';
import type { PendingCartProduct } from '@jane/shared/models';
import { useEcommApp } from '@jane/shared/providers';
import { Box, Drawer, Flex, useMobileMediaQuery } from '@jane/shared/reefer';
import { useRuntimeConfig } from '@jane/shared/runtime-config';

import { useCustomerDispatch } from '../../../../customer/dispatch';
import { stopShowBundlePossibilities } from '../../../../customer/redux/bundlePossibilities';
import {
  closeCart,
  removeDeliveryAddress,
} from '../../../../customer/redux/cart';
import { useCustomerSelector } from '../../../../customer/selectors';
import { useAuth } from '../../../../hooks/useAuth/useAuth';
import { useGuestCheckoutEnabled } from '../../../../hooks/useGuestCheckoutEnabled';
import { useSource } from '../../../../hooks/useSource';
import { getLocalizedMenuProductContent } from '../../../../lib/localizeProduct';
import { paths } from '../../../../lib/routes';
import { snakeCaseToCamelCaseStoreDeserializer } from '../../../../lib/store';
import { applicableFreeDeliveryConfig } from '../../../../lib/userFreeDeliveryConfigs';
import { getCurrentStoreSpecials } from '../../../../sources/storeSpecials';
import { CartToppers } from '../../cartToppers/cartToppers';
import { CartDrawerAddress } from '../cartDrawerAddress/cartDrawerAddress';
import { CartDrawerAlerts } from '../cartDrawerAlerts/cartDrawerAlerts';
import { CartDrawerDiscountTerms } from '../cartDrawerDiscountTerms/cartDrawerDiscountTerms';
import { CartDrawerFooter } from '../cartDrawerFooter/cartDrawerFooter';
import { useGetCartLineItems } from '../cartDrawerHooks/useGetCartLineItems';
import { useIsCheckoutDisabled } from '../cartDrawerHooks/useIsCheckoutDisabled';
import { useValidateDeliveryAddress } from '../cartDrawerHooks/useValidateDeliveryAddress';
import { CartDrawerItem } from '../cartDrawerItems/cartDrawerItem';
import { CartDrawerSpecials } from '../cartDrawerSpecials/cartDrawerSpecials';
import { CartDrawerSummary } from '../cartDrawerSummary/cartDrawerSummary';
import { CartDrawerToggle } from '../cartDrawerToggle/cartDrawerToggle';
import { Divider, FinePrintWrapper } from './cartDrawer.styles';

export const CartDrawer = () => {
  const {
    defaultLogin,
    errors,
    onAppleClick,
    onGoogleClick,
    register,
    resetError,
    resetPassword,
    showAppleLogin,
    showGoogleLogin,
  } = useAuth();

  const authentication = {
    errors,
    login: defaultLogin,
    onAppleClick,
    onGoogleClick,
    register,
    resetError,
    resetPassword,
    showAppleLogin,
    showGoogleLogin,
  };

  const isMobile = useMobileMediaQuery({});
  const { appMode } = useEcommApp();
  const { customer, cart, isChangingReservationModes, deliveryAddress } =
    useCustomerSelector(
      ({
        cart: { isChangingReservationModes, cart, deliveryAddress },
        customer,
      }) => ({
        appMode,
        customer,
        cart,
        deliveryAddress,
        isChangingReservationModes,
      })
    );

  const {
    brand_discounts_total,
    brand_discounts_service_fee_total,
    brand_discounts_total_with_fee,
    localized_products_content,
    products,
    reservation_mode,
  } = cart;

  const contentRef = useRef(null);
  const { data: store } = useGetStore(cart?.store.id);
  const navigate = useNavigate();
  const isGuestCheckoutEnabled = useGuestCheckoutEnabled();
  const dispatch = useCustomerDispatch();

  const shouldShowGold =
    useShouldShowGold({ store }) && !!brand_discounts_total;
  const hideJanePayBranding = store
    ? Boolean(store?.jane_pay_integration?.white_label) ||
      Boolean(!store?.jane_pay_integration?.enabled)
    : false;

  const authenticated = customer?.authenticated;

  const [schedules, setSchedules] = useState(
    snakeCaseToCamelCaseStoreDeserializer(store)
  );

  const [address, setAddress] = useState(
    getInitialDeliveryAddress(deliveryAddress)
  );

  const { data: specialsData } = useSource(
    getCurrentStoreSpecials(cart?.store.id)
  );

  const specials = specialsData ? specialsData.specials : [];

  const {
    deliveryValidationData,
    addressErrorMessage,
    resetAddressValidation,
    googleValidatedAddress,
  } = useValidateDeliveryAddress({
    store,
    address,
    street2: deliveryAddress?.street2,
  });

  const { isFramelessEmbedMode } = useRuntimeConfig();
  const { setUserLocation } = useUserPreferences();

  // this is the only way of retrieving customer location data in boost
  // since the marketplace is essentially scoped to a single store and the user is not prompted
  // to share their location when browsing products.

  useEffect(() => {
    if (isFramelessEmbedMode && googleValidatedAddress) {
      // set in local storage
      setUserLocation({
        ...googleValidatedAddress,
        hasResetLocation: true,
      });
    }
  }, [googleValidatedAddress, isFramelessEmbedMode, setUserLocation]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef?.current?.scrollTo(0, 0);
    }
  }, [cart.reservation_mode]);

  useEffect(() => {
    if (store) {
      // TODO(Kyr): move this into the `useGetStore` hook so we don't have to repeatedly deserialize
      setSchedules(snakeCaseToCamelCaseStoreDeserializer(store));
    }
  }, [store]);

  const cartLineItems = useGetCartLineItems();

  const checkoutDisabled = useIsCheckoutDisabled({
    cartLineItems,
    deliveryValidationData,
    store,
    schedules,
  });

  // .checkout() is wrapped in an authGate, so the user will be redirected to login if needed.
  const checkoutPath =
    !authenticated && isGuestCheckoutEnabled
      ? paths.guestCheckout()
      : paths.checkout();

  const handleSubmit = async () => {
    dispatch(stopShowBundlePossibilities());

    trackCheckoutClick(cart.reservation_mode);
    const storeIsAcceptingReservations =
      schedules?.isCurrentlyAcceptingReservations();

    if (reservation_mode !== 'delivery') {
      dispatch(removeDeliveryAddress());
    }

    if (storeIsAcceptingReservations) {
      dispatch(closeCart());
      navigate(checkoutPath);
    }
  };

  const handleSetAddress = (address: string) => {
    setAddress(address);
  };

  const cartSpecials = specials.find(
    (special) => special.id === cart.cart_discount_special_id
  )?.id;

  const groupSpecial = specials.find(
    (special) => special.id === cart.group_special_id
  )?.id;

  const appliedSpecials = cart?.products
    .map((product) => product.special_id)
    .filter((special, i, arr) => !!special && arr.indexOf(special) === i)
    .concat([cartSpecials, groupSpecial])
    .filter(Boolean);

  const freeDeliveryMinimum = applicableFreeDeliveryConfig({
    freeDeliveryConfigs: store.free_delivery_configs,
    discountedSubtotal: cartLineItems?.discountedSubtotal,
  });

  return (
    <>
      <Drawer.Header
        divider
        title="Bag"
        subtitle={store?.name ? `${store.name}, ${store.city}` : ''}
      >
        <CartDrawerToggle mt={16} store={store} schedules={schedules} />
      </Drawer.Header>

      <Drawer.Content noPadding ref={contentRef}>
        <Box background="grays-white" height="100%" width="100%">
          <Flex flexDirection="column" height="100%">
            {reservation_mode === 'delivery' && (
              <CartDrawerAddress
                addressErrorMessage={addressErrorMessage}
                initialDeliveryAddress={address}
                handleSetAddress={handleSetAddress}
                resetAddressValidation={resetAddressValidation}
              />
            )}

            <CartDrawerAlerts
              cartLineItems={cartLineItems}
              deliveryValidationData={deliveryValidationData}
            />

            <Box py={isMobile ? 8 : 24}>
              {products.map((product: PendingCartProduct) => {
                const productDetailLink = getProductDetailLink({
                  product,
                  appMode,
                  store,
                  routeAppMode: paths.getAppMode(),
                  routePartnerHostedConfig: paths.getPartnerHostedConfig(),
                });

                const localizedProduct = getLocalizedMenuProductContent(
                  product,
                  localized_products_content
                );
                return (
                  <CartDrawerItem
                    key={product?.id + product.price_id}
                    product={localizedProduct}
                    productDetailLink={productDetailLink}
                    specials={specials}
                  />
                );
              })}
            </Box>

            <Accordion>
              {shouldShowGold && (
                <>
                  <Divider />
                  <JaneGoldCallout
                    discountLabel={brand_discounts_total}
                    feeLabel={brand_discounts_service_fee_total}
                    hideJanePayBranding={hideJanePayBranding}
                    totalLabel={brand_discounts_total_with_fee}
                    variant="cart"
                    authentication={authentication}
                  />
                </>
              )}
              <Divider />
              <CartDrawerSpecials specials={specials} />
            </Accordion>

            <CartDrawerSummary
              cartLineItems={cartLineItems}
              isChangingReservationModes={isChangingReservationModes}
            />

            <CartToppers specials={specials} />

            {(appliedSpecials.length > 0 ||
              cartLineItems.discountTotal > 0 ||
              store.state === 'California') && (
              <FinePrintWrapper flexDirection="column">
                {(appliedSpecials.length > 0 ||
                  cartLineItems.discountTotal > 0) && (
                  <CartDrawerDiscountTerms
                    appliedSpecials={appliedSpecials}
                    freeDeliveryMinimum={
                      reservation_mode === 'delivery' &&
                      freeDeliveryMinimum?.cart_minimum
                    }
                    storeId={store.id}
                    storeName={store?.name}
                    specialLabel={
                      store.store_compliance_language_settings?.['specials']
                    }
                  />
                )}
              </FinePrintWrapper>
            )}
          </Flex>
        </Box>
      </Drawer.Content>
      <CartDrawerFooter
        checkoutDisabled={checkoutDisabled}
        deliveryValidationData={deliveryValidationData}
        handleSubmit={handleSubmit}
        total={cartLineItems.total}
        specials={specials}
      />
    </>
  );
};
